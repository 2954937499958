<template>
  <div class="sickinformation">
    <div class="breadcrumb">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <router-link to="/Patientinformation">
            <span @click="record">患者信息记录</span>
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item href="">
          查看统计数据
        </a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="datepicker">
      <div>
        <span class="ruls">* <span style="color:#000"> 开始时间</span></span>
        <a-date-picker
          style="margin-left:10px"
          v-model="startValue"
          show-time
          placeholder="开始日期/时间"
          @openChange="handleStartOpenChange"
          @change="onChange"
          @ok="ok"
        />
      </div>
      <div style="margin-left:20px">
        <span class="ruls">* <span style="color:#000"> 结束时间</span></span>
        <a-date-picker
          style="margin-left:10px"
          v-model="endValue"
          show-time
          placeholder="结束日期/时间"
          :open="endOpen"
          @openChange="handleEndOpenChange"
          :disabledDate="disabledDate"
        />
      </div>
      <a-button type="primary" class="search" @click="search">
        查询
      </a-button>
    </div>
    <!-- 折线图 -->
    <div
      style="width:100%;height:400px;margin-bottm:60px"
      id="chartLineBox"
    ></div>
    <div class="download">
      <a-button type="primary" @click="download">
        下载
      </a-button>
    </div>
    <div class="pageination">
      <ant-tabel
        :data="data"
        :pagination="pagination"
        :columns="columns"
        :loading="loading"
      ></ant-tabel>
    </div>

    <!-- 柱状图 -->
    <div
      style="width:100%;height:400px;margin-bottm:60px"
      id="chartBarBox"
    ></div>
  </div>
</template>
<script>
import excelUtil from '../../utils/dealwithExcelUtil';
import api from '@/api/index.js';
import storage from '../../storage/storage.js';
import echarts from 'echarts';
import moment from 'moment';
import AntTabel from '../Ant-tabel/Ant-tabel.vue';
const columns = [
  {
    title: '血糖(mmol/I)',
    dataIndex: 'gls',
    key: 'gls',
  },
  {
    title: 'RI速度(u/h)',
    dataIndex: 'advise_insulin_speed',
    key: 'advise_insulin_speed',
  },
  {
    title: '静脉推注',
    dataIndex: 'iv',
    key: 'iv',
  },
  {
    title: '采血部位',
    dataIndex: 'blood_collection',
    key: 'blood_collection',
  },
  {
    title: '监测方法',
    dataIndex: 'monitor_method',
    key: 'monitor_method',
  },
  // {
  //   title: 'EN',
  //   dataIndex: 'nutrition_en',
  //   key: 'nutrition_en',
  // },
  // {
  //   title: 'PN氨基酸',
  //   dataIndex: 'nutrition_pn_amino_acid',
  //   key: 'nutrition_pn_amino_acid',
  // },
  // {
  //   title: 'PN脂肪乳剂',
  //   dataIndex: 'nutrition_pn_intralipid',
  //   key: 'nutrition_pn_intralipid',
  // },
  // {
  //   title: '多腔袋全合一',
  //   dataIndex: 'multi_cavity_bag',
  //   key: 'multi_cavity_bag',
  // },
  // {
  //   title: 'PN碳水化合物',
  //   dataIndex: 'nutrition_pn_carbohydrate',
  //   key: 'nutrition_pn_carbohydrate',
  // },
  { title: '日期', dataIndex: 'createdAt', key: 'createdAt' },
];

const data = [];
export default {
  components: { AntTabel },
  props: {},
  data() {
    return {
      sickdata: '',
      startValue: null,
      endValue: null,
      bantime: null, //禁止时间
      endOpen: false,
      flag: 1,
      columns,
      data,
      createdAt: [],
      gls: [],
      gls_speed: [],
      lv: [],
      beforeTime: '',
      nowTime: '',
      loading: true,
      patientid: '',
      listData: [],
      accNutrition_en: [],
      accnutrition_pn_amino_acid: [],
      accnutrition_pn_intralipid: [],
      accmulti_cavity_bag: [],
      accnutrition_pn_carbohydrate: [],
      pagination: {
        current: 1, //当前页
        pageSize: 10, //每页几条数据
        total: 10, //总数
      },
    };
  },
  mounted() {
    //获取当前时间
    (this.patientid = storage.getItem('sickdata')[0].patient.objectId),
      (this.endValue = moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
      console.log(this.endValue)
    this.startValue = moment(new Date() - 24 * 60 * 60 * 1000).format(
      'YYYY-MM-DD HH:mm:ss'
    );
    this.sick();
    if (this.$route.query.text) {
      this.loadLineEchart();
      this.loadBarEchart();
    }
  },
  methods: {
    //患者信息记录数据源
    sick() {
      this.data = [];
      this.createdAt = [];
      this.gls = [];
      this.gls_speed = [];
      api
        .sickinquire(this.patientid, this.startValue, this.endValue)
        .then((data) => {
          console.log(data);
          (this.accNutrition_en = []),
            (this.accnutrition_pn_amino_acid = []),
            (this.accnutrition_pn_intralipid = []),
            (this.multi_cavity_bag = []),
            data.forEach((data) => {
              data.createdAt = moment(data.createdAt).format(
                'YYYY-MM-DD HH:mm:ss'
              );
              data.toJSON();
              // en
              let nutrition_en = [];
              let accnutrition_en = 0;
              data.attributes.nutrition_en.forEach((items) => {
                nutrition_en.push(
                  items.med.name + ':' + items.dose + '\xa0  \xa0'
                );
                data.attributes.nutrition_en = nutrition_en;
                accnutrition_en += Number(items.dose);
              });
              this.accNutrition_en.push(accnutrition_en);

              // PN氨基酸
              let nutrition_pn_amino_acid = [];
              let accnutrition_pn_amino_acid = 0;
              data.attributes.nutrition_pn_amino_acid.forEach((items) => {
                nutrition_pn_amino_acid.push(
                  items.med.name +
                    ':' +
                    items.dose +
                    '\xa0  \xa0 \xa0 \xa0 \xa0 \xa0'
                );
                data.attributes.nutrition_pn_amino_acid = nutrition_pn_amino_acid;
                accnutrition_pn_amino_acid += Number(items.dose);
              });
              this.accnutrition_pn_amino_acid.push(accnutrition_pn_amino_acid);
              //PN脂肪乳剂
              let nutrition_pn_intralipid = [];
              let accnutrition_pn_intralipid = 0;
              data.attributes.nutrition_pn_intralipid.forEach((items) => {
                nutrition_pn_intralipid.push(
                  items.med.name +
                    ':' +
                    items.dose +
                    '\xa0  \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0 \xa0'
                );
                data.attributes.nutrition_pn_intralipid = nutrition_pn_intralipid;
                accnutrition_pn_intralipid += Number(items.dose);
              });
              this.accnutrition_pn_intralipid.push(accnutrition_pn_intralipid);

              //多腔袋全合一
              let multi_cavity_bag = [];
              let accmulti_cavity_bag = 0;
              if (data.attributes.multi_cavity_bag) {
                data.attributes.multi_cavity_bag.forEach((items) => {
                  console.log(items);
                  multi_cavity_bag.push(
                    items.med.name + ':' + items.dose + '\xa0  \xa0'
                  );
                  data.attributes.multi_cavity_bag = multi_cavity_bag;
                  accmulti_cavity_bag += Number(items.dose);
                  console.log(accmulti_cavity_bag);
                });
                this.accmulti_cavity_bag.push(accmulti_cavity_bag);
                console.log(this.accmulti_cavity_bag);
                this.accnutrition_pn_carbohydrate.push(
                  data.attributes.nutrition_pn_carbohydrate
                );
              }
              this.data.push(data.toJSON());
              this.createdAt.push(data.createdAt);
              this.gls.push(data.toJSON().gls);
              this.gls_speed.push(data.toJSON().advise_insulin_speed);
            });
          this.loadLineEchart();
          this.loadBarEchart();
          this.loading = false;
        });
    },

    //时间框
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },

    //时间框
    handleEndOpenChange(open) {
      this.endOpen = open;
    },

    //搜索
    search() {
      if (this.startValue == null) {
        this.$message.warning('请选择开始时间');
        return;
      }
      if (this.endValue == null) {
        this.$message.warning('请选择结束时间');
        return;
      }
      if (moment(this.endValue).valueOf() < moment(this.startValue).valueOf()) {
        this.$message.warning('结束时间不能小于开始时间');
        return;
      }
      this.loading = true;
      api
        .sickinquire(this.patientid, this.startValue, this.endValue)
        .then(() => {
          this.sick();
          this.loading = false;
          this.loadLineEchart();
        });
    },

    //返回上一级页面
    record() {
      storage.removeItem('sickdata');
      this.$emit('opens', true);
    },

    disabledDate(current) {
      if (this.flag == 2) {
        return (
          current &&
          current > moment(this.bantime.split(' ')[0]).add(14, 'days')
        ); //选择目前开始日期两周之后的不可选，不包括当天
      }
    },
    ok() {
      this.bantime = moment(this.startValue).format('YYYY-MM-DD HH:mm:ss');
      this.flag = 2;
    },
    onChange(date, dateString) {
      this.bantime = moment(dateString).format('YYYY-MM-DD HH:mm:ss');
    },
    //折线echarts
    loadLineEchart() {
      let showed = this.data.length ? false : true;
      var myChat = echarts.init(document.getElementById('chartLineBox'));
      myChat.setOption({
        tooltip: {
          //设置tip提示
          trigger: 'axis',
        },
        title: {
          show: showed, // 是否显示title
          text: '暂无数据',
          left: 'center',
          top: 'center',
        },
        legend: {
          //设置区分（哪条线属于什么）
          orient: 'horizontal', //图例排列方式
          x: 'center', //可设定图例在左、右、居中
          y: 'bottom', //可设定图例在上、下、居中
          padding: [0, 50, 0, 0],
          data: ['血糖(mmol/L)', 'RI速度(U/H)'], //图例名称
          icon: 'line', //图例形状
          itemWidth: 30, //图例宽度
          itemHeight: 10, //图例高度
          Height: 5,
          itemGap: 20, // 设置间距
        },
        color: ['#4682B4', '#F4A460'],
        xAxis: {
          //设置x轴
          type: 'category',
          boundaryGap: false, //坐标轴两边不留白
          data: this.createdAt,
          nameTextStyle: {
            //坐标轴名称的文字样式
            color: '#FA6F53',
            fontSize: 16,
            padding: [0, 0, 0, 20],
          },
          axisTick: { show: false }, // X轴刻度
          axisLine: {
            show: true,
            //坐标轴轴线相关设置。
            lineStyle: {
              color: '#808080',
            },
          },
        },
        yAxis: {
          //Y轴线条
          nameTextStyle: {
            color: '#FA6F53',
            fontSize: 16,
            padding: [0, 0, 10, 0],
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#808080',
            },
          },
          axisTick: { show: false }, // Y轴刻度
          type: 'value',
          splitLine: {
            //网格线
            show: false,
          },
        },
        series: [
          {
            // symbol: 'none', //不显示折线原点
            name: '血糖(mmol/L)',
            data: this.gls,
            type: 'line', // 类型为折线图
            stack: '血糖(mmol/L',
            lineStyle: {
              normal: {
                color: '#4682B4',
                width: 5,
              },
            },
          },
          {
            // symbol: 'none', //不显示折线原点
            name: 'RI速度(U/H)',
            data: this.gls_speed,
            type: 'line',
            stack: 'RI速度(U/H)',
            lineStyle: {
              normal: {
                color: '#F4A460',
                width: 5,
              },
            },
          },
        ],
      });
      window.addEventListener('resize', () => {
        myChat.resize();
      });
    },

    // 柱状图
    loadBarEchart() {
      let showed = this.data.length ? false : true;
      console.log(this.accmulti_cavity_bag);
      var myChat = echarts.init(document.getElementById('chartBarBox'));
      myChat.setOption({
        tooltip: {
          //设置tip提示
          trigger: 'axis',
        },
        title: {
          show: showed, // 是否显示title
          text: '暂无数据',
          left: 'center',
          top: 'center',
        },
        xAxis: [
          {
            type: 'category',
            data: this.createdAt,
            axisPointer: {
              type: 'shadow',
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              formatter: '{value}',
            },
          },
        ],
        series: [
          {
            name: 'En',
            type: 'bar',
            data: this.accNutrition_en,
            itemStyle: {
              color: '#1890ff',
            },
          },
          {
            name: 'PN氨基酸',
            type: 'bar',
            data: this.accnutrition_pn_amino_acid,
            itemStyle: {
              color: '#66CDAA',
            },
          },
          {
            name: 'PN脂肪乳剂',
            type: 'bar',
            data: this.accnutrition_pn_intralipid,
            itemStyle: {
              color: '#87CEFA',
            },
          },
          {
            name: '多腔袋全合一',
            type: 'bar',
            data: this.accmulti_cavity_bag,
            itemStyle: {
              color: '#FFBB66',
            },
          },
          {
            name: 'PN碳水化合物',
            type: 'bar',
            data: this.accnutrition_pn_carbohydrate,
            itemStyle: {
              color: '#E63F00',
            },
          },
        ],
      });
      window.addEventListener('resize', () => {
        myChat.resize();
      });
    },
    //下载
    async download() {
      this.listData = [];
      await api.sickinquire(this.patientid).then((data) => {
        data.forEach((data) => {
          data.createdAt = moment(data.createdAt).format('YYYY-MM-DD HH:mm:ss');
          // let nutrition_en = [];
          // if (data.attributes.nutrition_en) {
          //   data.attributes.nutrition_en.forEach((items) => {
          //     nutrition_en.push(items.med.name + ':' + items.dose);
          //     data.attributes.nutrition_en = nutrition_en;
          //   });
          // }

          // let nutrition_pn_amino_acid = [];
          // if (data.attributes.nutrition_pn_amino_acid) {
          //   data.attributes.nutrition_pn_amino_acid.forEach((items) => {
          //     nutrition_pn_amino_acid.push(items.med.name + ':' + items.dose);
          //     data.attributes.nutrition_pn_amino_acid = nutrition_pn_amino_acid;
          //   });
          // }

          // let nutrition_pn_intralipid = [];
          // if (data.attributes.nutrition_pn_intralipid) {
          //   data.attributes.nutrition_pn_intralipid.forEach((items) => {
          //     nutrition_pn_intralipid.push(items.med.name + ':' + items.dose);
          //     data.attributes.nutrition_pn_intralipid = nutrition_pn_intralipid;
          //   });
          // }

          // let multi_cavity_bag = [];
          // if (data.attributes.multi_cavity_bag) {
          //   data.attributes.multi_cavity_bag.forEach((items) => {
          //     multi_cavity_bag.push(items.med.name + ':' + items.dose);
          //     data.attributes.multi_cavity_bag = multi_cavity_bag;
          //   });
          // }
          this.listData.push(data.toJSON());
        });
      });
      if (this.listData.length > 0) {
        const initColumn = [
          {
            title: '血糖(mmol/I)',
            dataIndex: 'gls',
            key: 'gls',
          },
          {
            title: 'RI速度(u/h)',
            dataIndex: 'advise_insulin_speed',
            key: 'advise_insulin_speed',
          },
          {
            title: '静脉推注',
            dataIndex: 'iv',
            key: 'iv',
          },
          {
            title: '采血部位',
            dataIndex: 'blood_collection',
            key: 'blood_collection',
          },
          {
            title: '监测方法',
            dataIndex: 'monitor_method',
            key: 'monitor_method',
          },
          // {
          //   title: 'EN',
          //   dataIndex: 'nutrition_en',
          //   key: 'nutrition_en',
          // },
          // {
          //   title: 'PN氨基酸',
          //   dataIndex: 'nutrition_pn_amino_acid',
          //   key: 'nutrition_pn_amino_acid',
          // },
          // {
          //   title: 'PN脂肪乳剂',
          //   dataIndex: 'nutrition_pn_intralipid',
          //   key: 'nutrition_pn_intralipid',
          // },
          // {
          //   title: '多腔袋全合一',
          //   dataIndex: 'multi_cavity_bag',
          //   key: 'multi_cavity_bag',
          // },
          // {
          //   title: 'PN碳水化合物',
          //   dataIndex: 'nutrition_pn_carbohydrate',
          //   key: 'nutrition_pn_carbohydrate',
          // },
        ];

        excelUtil.exportExcel(
          initColumn,
          this.listData,
          '患者信息数据统计.xlsx'
        );
      } else {
        this.$message.warning('暂无数据');
      }
    },
  },
};
</script>
<style scoped>
.sickinformation {
}
.breadcrumb {
  margin-top: 15px;
}
.breadcrumb >>> .ant-breadcrumb {
  font-size: 18px;
}
.datepicker {
  display: flex;
  margin-top: 20px;
}
.datepicker .ruls {
  font-size: 15px;
  color: red;
}
.search {
  margin-left: 30px;
}
.download {
  flex: 1;
  text-align: right;
}
</style>
