<template>
  <div class="container">
    <div v-if="state">
      <div class="search">
        <div class="search-left">
          <a-input placeholder="输入病案号" type="text" v-model="patientnum" />
          <a-button type="primary" @click="search">搜索</a-button>
        </div>
        <div
          class="export"
          v-if="
          getJurisdictionList.indexOf('patientinformation-downloadBtn') > -1
        "
        >
          <a-button type="primary" @click="derive">下载</a-button>
        </div>
      </div>

      <div v-if="getJurisdictionList.indexOf('patientinformation-checkList') > -1">
        <ant-tabel
          v-if="show"
          :data="data"
          :columns="columns"
          :loading="loading"
          @sick="sick"
          :status="status"
          @pitch="pitch"
          :pagination="pagination"
          @changePaging="changePaging"
        ></ant-tabel>
      </div>
    </div>
    <div v-if="!state">
      <!-- <sickinformation :sickrecord="sickrecord"></sickinformation> -->
      <div>
        <sickinformation :sickdata="sickdata"></sickinformation>
      </div>
    </div>
  </div>
</template>
<script>
import excelUtil from "../../utils/dealwithExcelUtil";
import api from "@/api/index.js";
import storage from "../../storage/storage.js";
import moment from "moment";
import sickinformation from "../sickinformation/sickinformation";
import AntTabel from "../Ant-tabel/Ant-tabel.vue";
const columns = [
  {
    title: "ID",
    dataIndex: "num",
    key: "num",
    width: 100,
    align: "center",
    scopedSlots: { customRender: "num" },
  },
  {
    title: "病案号",
    dataIndex: "record_number",
    key: "record_number",
    width: 400,
  },
  // { title: "年龄", dataIndex: "age", key: "age" },
  // { title: "性别", dataIndex: "sex", key: "sex" },
  // { title: "体重(KG)", dataIndex: "weight", key: "weight" },
  // { title: "是否有糖尿病", dataIndex: "is_diabetes", key: "is_diabetes" },
  // {
  //   title: "ICU主要诊断",
  //   dataIndex: "icu_diagnosis",
  //   key: "icu_diagnosis",
  //   scopedSlots: { customRender: "icu_diagnosis" },
  // },
  // { title: "入ICU时间", dataIndex: "icu_date", key: "icu_date" },
  {
    title: "医院信息",
    dataIndex: "hospitalName",
    key: "hospitalName",
    width: 500,
  },
  {
    title: "操作",
    key: "operation",
    scopedSlots: { customRender: "operations" },
    width: 300,
  },
];

const data = [];
export default {
  components: { AntTabel, sickinformation },
  data() {
    return {
      data,
      show: false,
      columns,
      state: true,
      loading: true,
      patientnum: "",
      getJurisdictionList: "",
      status: true,
      sickdata: "",
      mergeData: [],
      routes: [
        {
          path: "index",
          breadcrumbName: "Patient-Information Record",
        },
      ],
      pagination: {
        current: 1, //当前页
        pageSize: 10, //每页几条数据
        total: 10, //总数
      },
    };
  },
  created() {
    this.Patient();
  },
  mounted() {
    this.getJurisdiction();
  },

  methods: {
    getJurisdiction() {
      this.getJurisdictionList = storage.getItem("getJurisdiction");
    },
    Patient() {
      let obj = { name: this.patientnum, pagination: this.pagination };
      api
        .patient(obj)
        .then((res) => {
          this.data = [];
          this.pagination.total = res.count;
          res.data.forEach((data) => {
            if (data.hospital) {
              data.hospitalName = data.hospital.name;
            }
            data.is_diabetes == true
              ? (data.is_diabetes = "是")
              : (data.is_diabetes = "否");
            if (data.icu_date) {
              data.icu_date = moment(data.icu_date).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }
            data.key = data.objectId;
            this.data.push(data);
            this.show = true;
          });
          this.loading = false;
        })
        .catch(() => { });
    },
    opens(data) {
      this.state = data;
    },
    search() {
      if (this.patientnum) {
        this.pagination.current = 1;
      }
      this.Patient();
      this.loading = true;
    },
    sick(data) {
      storage.removeItem("sickdata");
      storage.setItem("sickdata", data);
    },
    //分页
    changePaging(page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.Patient();
    },
    //选中
    pitch(data) {
      this.mergeData = [];
      for (const i in this.data) {
        for (const j in data) {
          // console.log(data[j].createdAt);
          // data.[j].createdAt = moment(data[j].createdAt).format('YYYY-MM-DD HH:mm:ss');
          if (this.data[i].objectId == data[j].toJSON().patient.objectId) {
            this.mergeData.push({ ...this.data[i], ...data[j].toJSON() });
          }
        }
      }
    },
    //导出
    derive() {
      let mergeDatas = JSON.parse(JSON.stringify(this.mergeData));
      console.log(mergeDatas);
      mergeDatas.forEach((item) => {
        let nums = 0 + 1;
        let nutrition_en = [];
        if (item.nutrition_en) {
          item.nutrition_en.forEach((items) => {
            nutrition_en.push(items.med.name + ":" + items.dose);
            item.nutrition_en = nutrition_en;
          });
        }
        let nutrition_pn_amino_acid = [];
        if (item.nutrition_pn_amino_acid) {
          item.nutrition_pn_amino_acid.forEach((items) => {
            nutrition_pn_amino_acid.push(items.med.name + ":" + items.dose);
            item.nutrition_pn_amino_acid = nutrition_pn_amino_acid;
          });
        }
        let nutrition_pn_intralipid = [];
        if (item.nutrition_pn_intralipid) {
          item.nutrition_pn_intralipid.forEach((items) => {
            nutrition_pn_intralipid.push(items.med.name + ":" + items.dose);
            item.nutrition_pn_intralipid = nutrition_pn_intralipid;
          });
        }
        let multi_cavity_bag = [];
        if (item.multi_cavity_bag) {
          item.multi_cavity_bag.forEach((items) => {
            multi_cavity_bag.push(items.med.name + ":" + items.dose);
            item.multi_cavity_bag = multi_cavity_bag;
          });
        }
        item.principalDiagnosis =
          item.icu_diagnosis.type +
          item.icu_diagnosis.department +
          item.icu_diagnosis.diagnosis_name;
        item.createdAt = moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
      });
      console.log(mergeDatas);
      if (mergeDatas.length > 0) {
        const initColumn = [
          // {
          //   title: "ID",
          //   dataIndex: "num",
          //   key: "num",
          // },
          {
            title: "病案号",
            dataIndex: "record_number",
            key: "record_number",
          },
          {
            title: "医院信息",
            dataIndex: "hospitalName",
            key: "hospitalName",
            width: 300,
          },
          // {
          //   title: "年龄",
          //   dataIndex: "age",
          //   key: "age",
          // },
          // {
          //   title: "性别",
          //   dataIndex: "sex",
          //   key: "sex",
          // },
          // {
          //   title: "体重",
          //   dataIndex: "weight",
          //   key: "weight",
          // },
          // {
          //   title: "是否有糖尿病",
          //   dataIndex: "is_diabetes",
          //   key: "is_diabetes",
          // },
          // {
          //   title: "ICU主要诊断",
          //   dataIndex: "principalDiagnosis",
          //   key: "principalDiagnosis",
          // },
          // {
          //   title: "ICU主要时间",
          //   dataIndex: "icu_date",
          //   key: "icu_date",
          // },
          {
            title: "血糖(mmol/I)",
            dataIndex: "gls",
            key: "gls",
          },
          {
            title: "RI速度(u/h)",
            dataIndex: "advise_insulin_speed",
            key: "advise_insulin_speed",
          },
          {
            title: "静脉推注",
            dataIndex: "iv",
            key: "iv",
          },
          {
            title: "采血部位",
            dataIndex: "blood_collection",
            key: "blood_collection",
          },
          {
            title: "监测方法",
            dataIndex: "monitor_method",
            key: "monitor_method",
          },
          {
            title: "EN",
            dataIndex: "nutrition_en",
            key: "nutrition_en",
          },
          {
            title: "PN氨基酸",
            dataIndex: "nutrition_pn_amino_acid",
            key: "nutrition_pn_amino_acid",
          },
          {
            title: "PN脂肪乳剂",
            dataIndex: "nutrition_pn_intralipid",
            key: "nutrition_pn_intralipid",
          },
          {
            title: "多腔袋全合一",
            dataIndex: "multi_cavity_bag",
            key: "multi_cavity_bag",
          },
          {
            title: "PN碳水化合物",
            dataIndex: "nutrition_pn_carbohydrate122",
            key: "nutrition_pn_carbohydrate",
          },
          {
            title: "日期",
            dataIndex: "createdAt",
            key: "createdAt",
          },
        ];
        excelUtil.exportExcel(initColumn, mergeDatas, "患者信息数据记录.xlsx");
      } else {
        this.$message.warning("请选择要导入的数据");
      }
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.text) {
          setTimeout(() => {
            this.state = false;
          }, 100);
        } else {
          this.state = true;
        }
      },
      deep: true,
    },

    state: {
      handler(newName) {
        if (newName && this.$route.query.text) {
          this.state = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style scoped lang="less">
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.search-left {
  display: flex;
  align-items: center;
  .ant-input {
    width: 210px;
    height: 32px;
    border-radius: 2px;
  }
}

.search .ant-btn-primary {
  margin-left: 25px;
  height: 32px;
  border-radius: 2px;
  background: #2468f2;
}
.export {
  flex: 1;
  text-align: right;
}
.ant-table-body {
  overflow: auto;
  max-height: calc(100vh - 290px);
}

/deep/ .ant-table-body {
  max-height: calc(100vh - 265px) !important;
}
</style>
